import './Main.css';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';

import {
  IonApp,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonModal,
  IonRouterOutlet,
  IonRow,
  IonTitle,
  IonToolbar,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { closeOutline, contrastOutline, settingsOutline } from 'ionicons/icons';
import React, { lazy, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route } from 'react-router-dom';

import pr from './assets/images/pr-icon-min.png';
import usa from './assets/images/usa-icon-min.png';
import { useAppStore } from './store/appStore';
import { LANGUAGE_PR, LANGUAGE_USA } from './util/constants';

const Menu = lazy(() => import('./components/Menu'));
const About = lazy(() => import('./pages/about'));
const Home = lazy(() => import('./pages/home'));
const Instruments = lazy(() => import('./pages/instruments'));
const Cuatro = lazy(() => import('./pages/instruments/cuatro'));
const Guitar = lazy(() => import('./pages/instruments/guitar'));
const Cagueno = lazy(() => import('./pages/instruments/guitar/cagueno'));
const Chorreado = lazy(() => import('./pages/instruments/guitar/chorreado'));
const Fajardeno = lazy(() => import('./pages/instruments/guitar/fajardeno'));
const Jibaro = lazy(() => import('./pages/instruments/guitar/jibaro'));
const Parranda = lazy(() => import('./pages/parranda'));
const Song = lazy(() => import('./pages/songbook/Song'));
const SongList = lazy(() => import('./pages/songbook/SongList'));

setupIonicReact({
  swipeBackEnabled: false,
});

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
const MODAL_TRIGGER_ID = 'open-options';
const DARK_THEME_CLASS = 'dark';

const changeLanguage = (i18n: any, newLang: string): void => {
  i18n.changeLanguage(newLang);
};

const toggleTheme = (shouldAdd: boolean): void => {
  document.body.classList.toggle(DARK_THEME_CLASS, shouldAdd);
};

const toggleThemeHandler = (): void => {
  document.body.classList.toggle(DARK_THEME_CLASS);
};

// Initial theme setup
toggleTheme(prefersDark.matches);

const LanguageToggle: React.FC<{ i18n: any }> = ({ i18n }) => (
  <IonCol>
    {i18n.language === LANGUAGE_PR && (
      <IonButton onClick={() => changeLanguage(i18n, LANGUAGE_USA)}>
        <IonImg src={pr} className="language" />
      </IonButton>
    )}
    {i18n.language === LANGUAGE_USA && (
      <IonButton onClick={() => changeLanguage(i18n, LANGUAGE_PR)}>
        <IonImg src={usa} className="language" />
      </IonButton>
    )}
  </IonCol>
);

const SettingsModal: React.FC<{
  modalRef: React.RefObject<HTMLIonModalElement>;
  t: any;
  i18n: any;
}> = ({ modalRef, t, i18n }) => (
  <IonModal ref={modalRef} trigger={MODAL_TRIGGER_ID}>
    <IonHeader>
      <IonToolbar color="primary">
        <IonTitle className="toolbar-title">{t('settings')}</IonTitle>
        <IonButton
          onClick={() => modalRef.current?.dismiss()}
          slot="end"
          color="light"
          fill="clear">
          <IonIcon slot="icon-only" icon={closeOutline} className="color-white" />
        </IonButton>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">
      <IonGrid>
        <IonRow className="ion-align-items-center">
          <IonCol size="8">
            <p>{t('theme')}</p>
          </IonCol>
          <IonCol size="4" className="ion-text-right">
            <IonButton onClick={toggleThemeHandler}>
              <IonIcon slot="icon-only" icon={contrastOutline} />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow className="ion-align-items-center">
          <IonCol size="8">
            <p>{t('language')}</p>
          </IonCol>
          <IonCol size="4" className="ion-text-right">
            <LanguageToggle i18n={i18n} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  </IonModal>
);

const App: React.FC = () => {
  const [header] = useAppStore((state) => [state.header]);
  const { i18n, t } = useTranslation();
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonApp>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButton id={MODAL_TRIGGER_ID} slot="end" style={{ marginRight: '16px' }}>
            <IonIcon slot="icon-only" icon={settingsOutline} />
          </IonButton>
          <SettingsModal modalRef={modal} t={t} i18n={i18n} />
          <IonTitle className="toolbar-title">{header}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonReactRouter>
        <Menu />
        <IonRouterOutlet id="main">
          <Route path="/" exact>
            <Redirect to="/home/" />
          </Route>
          <Route path="/home/" exact component={Home} />
          <Route path="/songbook/" exact component={SongList} />
          <Route path="/song/:name" exact component={Song} />
          <Route path="/parranda/" exact component={Parranda} />
          <Route path="/about/" exact component={About} />
          <Route path="/instruments/" exact component={Instruments} />
          <Route path="/instruments/guitar" exact component={Guitar} />
          <Route path="/instruments/guitar/cagueno" exact component={Cagueno} />
          <Route path="/instruments/guitar/fajardeno" exact component={Fajardeno} />
          <Route path="/instruments/guitar/jibaro" exact component={Jibaro} />
          <Route path="/instruments/guitar/chorreado" exact component={Chorreado} />
          <Route path="/instruments/cuatro" exact component={Cuatro} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
