import './i18n';
import './util/analytics';

import i18next from 'i18next';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
// @ts-ignore
import { registerSW } from 'virtual:pwa-register';

import App from './App';
import Fallback from './pages/fallback';
import ErrorBoundary from './util/ErrorBoundary';

const ROOT_ELEMENT_ID = 'root';

const handleSWRegistrationError = (error: any) => {
  console.error('Service Worker registration error:', error);
};

const handleSWNeedRefresh = () => {
  // handle need refresh logic
};

const handleSWOfflineReady = () => {
  // handle offline ready logic
};

const initializeServiceWorker = () => {
  registerSW({
    onNeedRefresh: handleSWNeedRefresh,
    onOfflineReady: handleSWOfflineReady,
    onRegisterError: handleSWRegistrationError,
  });
};

const renderApp = () => {
  const rootElement = document.getElementById(ROOT_ELEMENT_ID);
  if (!rootElement) {
    throw new Error(`Cannot find root element with ID '${ROOT_ELEMENT_ID}'`);
  }
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Suspense fallback={<Fallback />}>
          <App />
        </Suspense>
      </ErrorBoundary>
    </React.StrictMode>,
  );
};

i18next.on('initialized', renderApp);
initializeServiceWorker();
